import {
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useToast,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { PromoMediaTemplateForm, SocialProfilesForm } from 'src/components/molecules';
import { MarketplaceContext } from 'src/contexts/marketplace';
import { useAuth } from 'src/utils/auth';
import { apiRequest } from 'src/utils/fetchUtils';
import SocialSharing from '../category-edit-form/social-sharing';
import { useRouter } from 'next/router';

const SocialMediaForm = (): JSX.Element => {
  // TODO: Fetch a non-cached marketplace payload from the API on every load
  const { marketplace, setMarketplace } = useContext(MarketplaceContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getToken } = useAuth();
  const toast = useToast();
  const router = useRouter();

  const updateMarketplaceHandler = async (
    payload: object,
    successMessage: string,
    errorMessage: string,
    includeFiles = false,
  ) => {
    setIsSubmitting(true);

    const token = await getToken();
    const url = `${process.env.NEXT_PUBLIC_API_HOST}/marketplaces/${marketplace?.id}/`;

    try {
      const updatedMarketplace = await apiRequest('PUT', url, token, payload, includeFiles);
      setMarketplace(updatedMarketplace);
      toast({
        title: 'Success',
        description: successMessage,
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'bottom',
      });
      // catch-all solution for intermittent state not updating after save bug
      router.reload();
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    } finally {
      setIsSubmitting(false);
      // Clear the marketplace local storage
      const hostname = window.location.hostname;
      localStorage.removeItem(`marketplace-${hostname}`);
    }
  };

  return (
    <Stack w="full">
      <Text fontSize="2xl" pb={8}>
        Social Media
      </Text>
      <Accordion defaultIndex={0}>
        <AccordionItem>
          <AccordionButton>
            <Text as="b" fontSize="lg" w="full" textAlign="start">
              Social Profiles
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <SocialProfilesForm
              updateMarketplaceHandler={updateMarketplaceHandler}
              isSubmitting={isSubmitting}
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Text as="b" fontSize="lg" w="full" textAlign="start">
              Promo Media Template
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <PromoMediaTemplateForm
              updateMarketplaceHandler={updateMarketplaceHandler}
              isSubmitting={isSubmitting}
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Text as="b" fontSize="lg" w="full" textAlign="start">
              Social Sharing
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <SocialSharing />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

export default SocialMediaForm;
